import { Avatar, Divider, Grid, Typography, Button } from "@mui/material";
import React, { useMemo, useRef } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import axios from "axios";
import endPoints from "../../config/endPoint";
import { Editor } from "react-draft-wysiwyg";
import CustomDatePicker from "../../components/CustomDateField/Index";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { MdLogout } from "react-icons/md";
import { useLocation } from "react-router-dom";
import url from "../../API";
import ImageWithPopup from "../../components/ImagewithPop/Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import DashboardHeader from "../../components/DashHeader/Index";
import JoditEditor from "jodit-react";
export default function Customer_testimonial() {
  const navigate = useNavigate();
  const classes = offersStyles();
  const token = localStorage.getItem("tkn");

  const location = useLocation();
  const { updateId } = location.state || {};
  const [list, setList] = React.useState([]);
  const [visalist, setVisalist] = React.useState([]);
  const [state, setState] = React.useState({
    name: "",
    image: "",
    description: "",
    date: "",
    location: "",
    title: "",
    rating: "",
  });
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "",
    }),
    []
  );
  
  React.useEffect(() => {
    if (updateId) {
      setState({
        name: updateId.customer_name,
        image: updateId.customer_image,
        description: updateId.description,        
        date: updateId.date,
        location: updateId.customer_location,
        title: updateId.title,
        rating: updateId.rating,
        isUpdate: true,
        id: updateId.id,
      });
    }
  }, []);

  const handleSaveOfficer = async () => {
    const { name, image, description, location, title, date, rating } = state;

    // Validation to check for non-empty fields
    if (
      !name ||
      !image ||
      !description ||
      !location ||
      !title ||
      !date ||
      !rating
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        customer_name: name,
        customer_image: image,
        description: description,
        customer_location: location,
        title: title,
        date: date,
        rating: rating,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "customertestimonial/createcustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();
      //console.log(actualData);

      if (actualData.status == 200) {
        navigate("/customer_testimoniallist");
        localStorage.setItem("createCust", 1);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const handleUpdateOffice = async (id) => {
    const { name, image, description, location, title, date, rating } = state;

    // Validation to check for non-empty fields
    if (
      !name ||
      !image ||
      !description ||
      !location ||
      !title ||
      !date ||
      !rating
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const data = {
        customer_name: name,
        customer_image: image,
        description: description,
        customer_location: location,
        title: title,
        date: date,
        id: id,
        rating: rating,
      };

      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };

      const res = await fetch(
        url + "customertestimonial/updatecustomertestimonial",
        requestOptions
      );

      const actualData = await res.json();
      //console.log(actualData);

      if (actualData.status === 200) {
        navigate("/customer_testimoniallist");
        localStorage.setItem("updateCust", 1);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>
              Customer Testimonial
            </p>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={7}>
              <CustomInputField
                fullWidth
                label="Customer Name"
                value={state.name}
                onChange={(e) => {
                  const input = e.target.value;
                  // Remove non-alphabetic characters
                  const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                  // Ensure input does not exceed 50 characters
                  const trimmedInput = validInput.slice(0, 50);
                  setState({
                    ...state,
                    name: trimmedInput,
                  });
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <p>Date</p>
              <input
                required=""
                style={{
                  padding: "10px",
                  // borderColor: "rgba(148,158,171,0.32)",
                  boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                  width: "100%",
                  borderRadius: "10px",
                  border: "none",
                  marginTop: "20px",
                }}
                type="date"
                value={state.date}
                onChange={(e) =>
                  setState({
                    ...state,
                    date: e.target.value,
                  })
                }
                max={new Date().toISOString().split("T")[0]}
              />
            </Grid>
            <Grid item xs={7}>
              <CustomInputField
                fullWidth
                label=" Testimonial Title"
                value={state.title}
                // onChange={(e) => {
                //   setState({
                //     ...state,
                //     title: e.target.value,
                //   });
                // }}
                onChange={(e) => {
                  const input = e.target.value;
                  // Remove non-alphabetic characters
                  // const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                  // // Ensure input does not exceed 50 characters
                  // const trimmedInput = validInput.slice(0, 50);
                  setState({
                    ...state,
                    title: input,
                  });
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <CustomInputField
                fullWidth
                label="Location"
                value={state.location}
                onChange={(e) => {
                  const input = e.target.value;
                  // Remove non-alphabetic characters
                  const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                  // Ensure input does not exceed 50 characters
                  const trimmedInput = validInput.slice(0, 50);
                  setState({
                    ...state,
                    location: trimmedInput,
                  });
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <CustomInputField
                fullWidth
                label="Customer Photo"
                type="file"
                onChange={(e) => {
                  const imageRef = ref(storage, `/customers+${Math.random()}`);
                  uploadBytes(imageRef, e.target.files[0]).then(() => {
                    getDownloadURL(imageRef)
                      .then((url) => {
                        // link = url;
                        ////console.log("Image URL:", url);
                        // localStorage.setItem("customer", url);
                        // //console.log("variable value " + state.image);
                        // handleSaveVisaCategory();
                        setState({
                          ...state,
                          image: url,
                        });
                      })
                      .catch((error) => {
                        //console.log(error);
                      });
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              {state.image && (
                <>
                  <p> image</p>
                  {/* <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={state.image}
                    style={{
                      margin: "auto",
                    }}
                  /> */}
                  <ImageWithPopup src={state.image} alt="Example Image" />
                </>
              )}
            </Grid>
            <Grid item xs={7}>
              <CustomInputField
                fullWidth
                label="Rating"
                value={state.rating}
                onChange={(e) => {
                  const input = e.target.value;
                  // Remove non-digit characters
                  const numericInput = input.replace(/[^0-9]/g, "");
                  // Ensure input is within the range 1-5
                  const validInput = numericInput > 5 ? 5 : numericInput;

                  setState({
                    ...state,
                    rating: validInput,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              Description
              
              <JoditEditor
                  ref={editor}
                  value={state.description}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) =>
                    setState({
                      ...state,
                      description: newContent,
                    })
                  } // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                  config={config}
                />
            </Grid>
            <Grid item xs={12}>
              {state.isUpdate ? (
                <Button
                  variant="contained"
                  fullWidth
                  color="warning"
                  onClick={(e) => {
                    handleUpdateOffice(state.id);
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={(e) => {
                    handleSaveOfficer();
                  }}
                >
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
