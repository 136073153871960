import { Divider, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { AiFillHome } from "react-icons/ai";
import {
  FaArrowRight,
  FaHandshake,
  FaThumbtack,
  FaRupeeSign,
  FaPaperclip,
  FaPaperPlane,
  FaSignInAlt,
  FaUserFriends,
  FaPassport,
  FaFileAlt,
  FaUser,
  FaUserCheck
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoMail } from "react-icons/io5";
// import NotificationBtn from "../../components/Notification_btn/Index";
import DashboardLayout from "../../layouts";
import useStyles from "../../styles/pages/Dashboard/index.style";
import AccountCard from "./component/card";
import axios from "axios";
import endPoints from "../../config/endPoint";
import DashboardCard from "./component/baseCard";
// import CustomDateRangePicker from "../../components/CustomDateRange";
import RevenueChart from "./component/chart";
import CustomDateRangePicker from "../../components/CustomDateRange";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { Search } from "@mui/icons-material";
import { CiMail } from "react-icons/ci";
import NotificationBtn from "../../components/NotificationBtn/Index";
import { IoHomeOutline } from "react-icons/io5";
import DashboardHeader from "../../components/DashHeader/Index";
export default function DashBoard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [date, setDate] = React.useState({
    startDate: null,
    endDate: null
  });

  const token = localStorage.getItem("token");
  const [countState, setCountState] = React.useState({
    visa_no: null,
    applicant_no: null,
    user_no: null
  });
  React.useEffect(() => {
    const login = async () => {
      try {
        const loginHeaders = new Headers();
        loginHeaders.append("Content-Type", "application/json");
        const authToken = localStorage.getItem("tkn");
        if (authToken) {
          loginHeaders.append("Authorization", `Bearer ${authToken}`);
        }

        const requestOptions = {
          method: "GET",
          headers: loginHeaders
        };
        const res = await fetch(url + "visa/totaldata", requestOptions);
        const actualData = await res.json();
        if (actualData.status == 200) {
          // //console.log(actualData.access);
          // //console.log(actualData);
          setCountState({
            visa_no: actualData.total_visa,
            applicant_no: actualData.total_applicant,
            user_no: actualData.total_user
          });
        } else {
          toast.error("Data can't be fetched", {
            position: "top-center"
          });
        }
        //console.log(actualData);
      } catch (err) {
        //console.log(err);
      }
    };
    login();
  }, []);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [notifications, setNotifications] = useState([
    { message: "23 people applied recently", icon: <CiMail /> },
    { message: "10 new messages", icon: <CiMail /> },
    { message: "5 people has been rejected", icon: <CiMail /> },
    {
      message: "You have a new offer.",
      icon: <CiMail />
    },
    { message: "200 people got his voucher", icon: <CiMail /> }
  ]);

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px"
            }}
          >
            {" "}{/* <Typography variant="h5" color={"primary"}>
              <AiFillHome /> DashBoard
            </Typography> */}
            <IoHomeOutline style={{ fontSize: "22px" }} />
            <p style={{ paddingTop: "4px", fontSize: "22px" }}>DashBoard</p>
          </div>

          {/* <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div> */}
        </div>
        <div className={classes.contentMain}>
          {/* <Grid item xs={6}>
              <AccountCard
                title={"Payouts"}
                count={countState.payouts}
                color="info"
                Icon={<FaHandshake className={classes.icon} />}
                actionText={
                  <Typography display={"flex"} gap={1} alignItems="center">
                    View Detailed <FaArrowRight />
                  </Typography>
                }
                actionCallback={(e) => {
                  navigate("payouts");
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <AccountCard
                title={"Total Coupon"}
                Icon={<FaThumbtack className={classes.icon} />}
                count={countState.coupon}
                color="warning"
                actionText={
                  <Typography display={"flex"} gap={1} alignItems="center">
                    Create Coupon <FaArrowRight />
                  </Typography>
                }
                actionCallback={() => {
                  navigate("coupons");
                }}
              />
            </Grid> */}
          <div style={{ display: "flex", gap: "20px", paddingTop: "8px" }}>
            <div style={{ flex: "1 1 18%" }}>
              <DashboardCard
                title={"Total Visa"}
                Icon={<FaPassport className={classes.icon} />}
                count={countState.visa_no}
                color="box1"
              />
            </div>

            <div style={{ flex: "1 1 18%" }}>
              <DashboardCard
                title={"Total Users"}
                Icon={<FaUser className={classes.icon} />}
                count={countState.user_no}
                color="box2"
              />
            </div>
            <div style={{ flex: "1 1 18%" }}>
              <DashboardCard
                title={"Total Active Users"}
                Icon={<FaUser className={classes.icon} />}
                count={countState.user_no}
                color="box3"
              />
            </div>
            <div style={{ flex: "1 1 18%" }}>
              <DashboardCard
                title={"Total Applications"}
                Icon={<FaFileAlt className={classes.icon} />}
                count={countState.applicant_no}
                color="box4"
              />
            </div>
            <div style={{ flex: "1 1 18%" }}>
              <DashboardCard
                title={"Total revenue earned (INR)"}
                Icon={<FaRupeeSign className={classes.icon} />}
                count="200"
                color="box5"
              />
            </div>
          </div>

          <Grid item xs={12} sm={12} md={12}>
            <CustomDateRangePicker date={date} setDate={setDate} />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <RevenueChart date={date} />
          </Grid>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
