import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useMemo, useRef } from "react";
import { FaEdit, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import url from "../../API";
import DashboardHeader from "../../components/DashHeader/Index";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import JoditEditor from "jodit-react";
export default function CouponAdd() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [mainlist, setMainList] = React.useState([]);
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "",
    }),
    []
  );
  const [state, setState] = React.useState({
    coupon_code: "",
    searchText: "",
    off_value: "",
    minimum_amount: "",
    maximum_discount: "",
    start_date: "",
    end_date: "",
    off_type: " ",
    coupon_description: "",
    isUpdate: false,
  });
  const [couponlist, setcouponlist] = React.useState([]);
  const [visaidList, setVisaidList] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deletecouponId, setDeletecouponId] = React.useState(null);
  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(url + "coupon/getallcoupon", requestOptions);
      const actualData = await res.json();
      //console.log(actualData);
      setcouponlist(actualData.Coupon);
    } catch (e) {
      //console.log(e);
    }
  }

  React.useEffect(() => {
    table();
  }, []);
  const handleDelete = (idx) => {
    setDeletecouponId(idx);
    setConfirmDialogOpen(true);
  };
  const handleSaveVisaCategory = () => {
    axios
      .post(
        url + "coupon/createcoupon",
        {
          coupon_code: state.coupon_code,
          off_value: parseInt(state.off_value),
          minimum_amount: parseInt(state.minimum_amount),
          maximum_discount: parseInt(state.maximum_discount),
          coupon_startdate: state.start_date,
          coupon_enddate: state.end_date,
          off_type: state.off_type,
          coupon_description: state.coupon_description,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Created Sucessfully", {
            position: "top-center",
          });
          window.scrollTo({ top: 800, behavior: "smooth" });
        }

        table();
      })
      .catch((error) => {
        //console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      coupon_code: "",
      searchText: "",
      off_value: "",
      minimum_amount: "",
      maximum_discount: "",
      off_type: " ",
      start_date: "",
      end_date: "",
      coupon_description: "",
    });
  };
  const handleVisaCategoryDelete = () => {
    axios
      .post(
        url + "coupon/deletecoupon",
        {
          id: deletecouponId,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Deleted Sucessfully", {
            position: "top-center",
          });
        }

        table();
      })
      .catch((error) => {
        //console.error("Error fetching data:", error);
        // Handle error here
      });
    setConfirmDialogOpen(false);
  };
  const handleUpdateVisaCategory = (idx) => {
    axios
      .post(
        url + "coupon/updatecoupon",
        {
          id: idx,
          coupon_code: state.coupon_code,
          off_value: state.off_value,
          minimum_amount: parseInt(state.minimum_amount),
          maximum_discount: parseInt(state.maximum_discount),
          coupon_startdate: state.start_date,
          coupon_enddate: state.end_date,
          off_type: state.off_type,
          coupon_description: state.coupon_description,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Updated Sucessfully", {
            position: "top-center",
          });
          window.scrollTo({ top: 800, behavior: "smooth" });
        }

        table();
      })
      .catch((error) => {
        //console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      coupon_code: "",
      searchText: "",
      off_value: "",
      minimum_amount: "",
      maximum_discount: "",
      off_type: " ",
      start_date: "",
      end_date: "",
      coupon_description: "",
      isUpdate: false,
    });
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>Coupon</p>
          </div>
        </div>
        {/* <Divider className={classes.divider} /> */}
        <div className={classes.contentMain}>
          {/* <p className={classes.title}>Coupon</p> */}
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputField
                  label="Coupon Code"
                  fullWidth
                  value={state.coupon_code}
                  onChange={(e) => {
                    const validInput = e.target.value;

                    // Trim the input to a maximum of 50 characters
                    const trimmedInput = validInput.slice(0, 50);

                    // Update the state with the trimmed input
                    setState({
                      ...state,
                      coupon_code: trimmedInput,
                    });
                  }}
                />


              </Grid>
              <Grid item xs={12}>
                Coupon Description
                {/* <Editor
                  label="Coupon Description"
                  editorState={state.coupon_description}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  // value={state.country_description}
                  onEditorStateChange={(e) => {
                    setState({
                      ...state,
                      coupon_description: e,
                    });
                  }}
                /> */}
                <JoditEditor
                  ref={editor}
                  value={state.coupon_description}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) =>
                    setState({
                      ...state,
                      coupon_description: newContent,
                    })
                  } // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                  config={config}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Minimum Amount ($)"
                  fullWidth
                  value={state.minimum_amount}
                  onChange={(e) => {
                    const input = e.target.value;

                    let validInput = input.replace(/[^0-9]/g, ""); // Only accept numbers
                    validInput = validInput.slice(0, 4); 

                    setState({
                      ...state,
                      minimum_amount: validInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Maximum Discount ($)"
                  fullWidth
                  value={state.maximum_discount}
                  onChange={(e) => {
                    const input = e.target.value;
                    let validInput = input.replace(/[^0-9]/g, ""); // Only accept numbers
                    validInput = validInput.slice(0, 4); 

                    setState({
                      ...state,
                      maximum_discount: validInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={["Percentage off", "Flat off"]}
                  value={
                    state.off_type == 1
                      ? "Percentage off"
                      : state.off_type == 2
                        ? "Flat off"
                        : ""
                  }
                  changeCallBack={(e, v) => {
                    //console.log(v);
                    if (v == "Percentage off") {
                      setState({
                        ...state,
                        off_type: 1,
                      });
                    } else if (v == "Flat off") {
                      setState({
                        ...state,
                        off_type: 2,
                      });
                    } else if (v === null || v === "") {
                      // Reset the state when the selection is cleared
                      setState({
                        ...state,
                        off_type: 0, // Or any value representing "no option selected"
                      });
                    }
                  }}
                  label="Offer Type"
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Offer Value"
                  fullWidth
                  value={state.off_value}
                  onChange={(e) => {
                    const input = e.target.value;
                    let validInput = input;

                    if (state.off_type === 1) {
                      validInput = input.replace(/[^0-9]/g, "").slice(0, 2); // Only 2 digits allowed
                    } else if (state.off_type === 2) {
                      validInput = input.replace(/[^0-9]/g, "").slice(0, 4); // Only 4 digits allowed
                    }

                    setState({
                      ...state,
                      off_value: validInput ,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <p>Start Date</p>
                <input
                  required
                  style={{
                    padding: "10px",
                    boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                    width: "100%",
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                  }}
                  type="date"
                  value={state.start_date}
                  onChange={(e) =>
                    setState({
                      ...state,
                      start_date: e.target.value,
                      end_date: state.end_date && state.end_date < e.target.value ? "" : state.end_date, // Reset end_date if it's before the new start_date
                    })
                  }
                  max={new Date().toISOString().split("T")[0]} // Prevent future dates for start_date
                />
              </Grid>

              <Grid item xs={5}>
                <p>End Date</p>
                <input
                  required
                  style={{
                    padding: "10px",
                    boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                    width: "100%",
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                  }}
                  type="date"
                  value={state.end_date}
                  onChange={(e) =>
                    setState({
                      ...state,
                      end_date: e.target.value,
                    })
                  }
                  min={state.start_date ? new Date(new Date(state.start_date).getTime() + 24 * 60 * 60 * 1000).toISOString().split("T")[0] : ""}
                />
              </Grid>

              {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.coupon_code &&
                        !!state.off_value &&
                        !!state.minimum_amount &&
                        !!state.maximum_discount &&
                        !!state.off_type &&
                        !!state.start_date &&
                        !!state.end_date &&
                        !!state.coupon_description
                      ) {
                        handleUpdateVisaCategory(state.id);
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                  // onClick={(e) =>
                  //   {
                  //   handleUpdateVisaCategory(state.id);
                  //   }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.coupon_code &&
                        !!state.off_value &&
                        !!state.minimum_amount &&
                        !!state.maximum_discount &&
                        !!state.off_type &&
                        !!state.start_date &&
                        !!state.end_date &&
                        !!state.coupon_description
                      ) {
                        handleSaveVisaCategory();
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                  // onClick={(e) => {
                  //   handleSaveVisaCategory();
                  // }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Coupon List</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={[
                "Sl No.",
                "Coupon Code",
                //  "Coupon Description",
                "Offer Type",
                "Offer Value",
                "Max Amount",
                "Min Amount",
                "Action",
              ]}
              rows={[
                ...couponlist
                  .filter((l) => {
                    const cpn_code =
                      (l.coupon_code ?? "").toString().toLowerCase() || "";

                    const min_am =
                      (l.minimum_amount ?? "").toString().toLowerCase() || "";

                    const max_am =
                      (l.maximum_discount ?? "").toString().toLowerCase() || "";
                    // const off_val = l.off_value?.toLowerCase() || "";
                    const off_val =
                      (l.off_value ?? "").toString().toLowerCase() || "";
                    // const cpn_desc =
                    //   JSON.parse(
                    //     l.coupon_description
                    //   )?.blocks[0].text?.toLowerCase() || "";

                    const searchLower = (state.searchText || "").toLowerCase();

                    return (
                      cpn_code.includes(searchLower) ||
                      min_am.includes(searchLower) ||
                      max_am.includes(searchLower) ||
                      off_val.includes(searchLower) ||
                      //  cpn_desc.includes(searchLower) ||
                      searchLower === undefined
                    );
                  })

                  .map((vs, idx) => [
                    idx + 1,
                    vs.coupon_code,
                    // JSON.parse(vs.coupon_description).blocks[0].text,
                    vs.off_type == 1
                      ? "Percentage off"
                      : vs.off_type == 2
                        ? "Flat off"
                        : "",
                    vs.off_value,
                    vs.minimum_amount,
                    vs.maximum_discount,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleDelete(vs.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) => {
                          setState({
                            coupon_code: vs.coupon_code,
                            coupon_description: vs.coupon_description,
                            minimum_amount: vs.minimum_amount,
                            maximum_discount: vs.maximum_discount,
                            off_type: vs.off_type,
                            off_value: vs.off_value,
                            start_date: vs.coupon_startdate,
                            end_date: vs.coupon_enddate,
                            isUpdate: true,
                            id: vs.id,
                          });
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleVisaCategoryDelete}
      />
    </div>
  );
}
