import {
  Box,
  Button,
  // Button,
  Drawer,
  Link,
} from "@mui/material";
// material
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { MHidden } from "../../components/@material-extend";
import React from "react";
// components
// import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import logo from "../visa-logo.png";
import sidebarConfig from "./sidebar.config";
import { Divider } from "@mui/material";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  display: "auto",
  scrollbarWidth: "auto",
  minHeight: "70%",
  //WebkitScrollSnapType:"-moz-initial",
  msOverflowStyle: "auto",
  backgroundColor: "#F0EEEE",
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  // backgroundColor: theme.palette.grey[200],
  backgroundColor: "#F0EEEE",
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("t");
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    //console.log(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Box sx={{ px: 2.5, py: 1.2 }}></Box>

      <Box
        sx={{
          mb: 5,
          mx: 2.5,
          flexGrow: 0.5,
        }}
        width={"80%"}
      >
        <Link underline="none" href="/dashboard">
          <AccountStyle
            style={{
              borderRadius: "10px",
            }}
          >
            <img src={logo} alt="VISALEETS" />
          </AccountStyle>
        </Link>
        <Divider sx={{ my: 2, borderColor: "#e3e3e3", borderBottomWidth: 2 }} />
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <SidebarBoost /> */}
      {token && (
        <Button
          variant="contained"
          className="sidebar_logout_btn"
          color="warning"
          onClick={(e) => {
            localStorage.clear("token");
            navigate("/");
          }}
          style={{
            width: "60%",
            margin: "0px auto",
            fontSize: 15,
            display: "flex",
            gap: 10,
          }}
        >
          {" "}
          <FaSignOutAlt /> Logout
        </Button>
      )}
      <Box sx={{ flexGrow: 0.2 }} />
    </>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              //background: (theme) => theme.palette.primary.main,
              background: "#F0EEEE",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              // bgcolor: (theme) => theme.palette.primary.main,
              bgcolor: "#F0EEEE",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
