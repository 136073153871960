import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { useLocation } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import url from "../../API";
import DashboardHeader from "../../components/DashHeader/Index";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import { useNavigate } from "react-router";
export default function VisaComparePricing() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const location = useLocation();
  const { visaId } = location.state || {};
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deletepriceId, setDeletepriceId] = React.useState(null);
  const [state, setState] = React.useState({
    provided_name: "",
    price: "",
    searchText: "",
    id: "",
    visa_name: "",
    visa_id: "",
    isUpdate: false,
  });
  const [visaList, setVisaList] = React.useState([]);
  const [comparelist, setCmplist] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);
  const [visaName, setVisaName] = React.useState("");

  async function visa_table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = { visa_id: visaId };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "visa/visapricecomparebyvisaid",
        requestOptions
      );
      const actualData = await res.json();
      //console.log(actualData);
      setCmplist(actualData.price_compare_details);
      setState((prevState) => ({
        ...prevState,
        visa_id: visaId,
        visa_name: actualData.visa_name,
      }));
      setVisaName(actualData.visa_name);
    } catch (e) {
      //console.log(e);
    }
  }
  const handleDelete = (idx) => {
    setDeletepriceId(idx);
    setConfirmDialogOpen(true);
  };

  // const getVisaName = () => {
  //   return visaList
  //     .map((vs) => {
  //       const plInfo = comparelist.find((pl) => pl.visa_id == vs.id);

  //       if (plInfo) {
  //         return {
  //           plInfo,
  //           visa_name: vs.visa_name,
  //         };
  //       }
  //       return null; // or any other value you prefer for undefined cases
  //     })
  //     .filter(Boolean); // filter out undefined values from the array
  // };
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const visa_data = await visa_table();
      } catch (error) {
        //console.error("Error fetching data:", error);
        // Handle errors if necessary
      }
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    // const partnerfullinfo = getVisaName();
    // setMainList(partnerfullinfo);
    // //console.log("combined array");
    // //console.log(partnerfullinfo);
  }, [comparelist]);

  const handleSaveVisaCategory = () => {
    axios
      .post(
        url + "pricecompair/createpricecompair",
        {
          provided_name: state.provided_name,
          price: state.price,
          visa_id: state.visa_id,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Created Sucessfully", {
            position: "top-center",
          });
          window.scrollTo({ top: 500, behavior: "smooth" });
        }
        visa_table();
      })
      .catch((error) => {
        //console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      provided_name: "",
      price: "",
    });
  };
  const handleVisaCategoryDelete = () => {
    axios
      .post(
        url + "pricecompair/deletepricecompair",
        {
          id: deletepriceId,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Deleted Sucessfully", {
            position: "top-center",
          });
        }

        visa_table();
      })
      .catch((error) => {
        //console.error("Error fetching data:", error);
        // Handle error here
      });
    setConfirmDialogOpen(false);
  };
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleUpdateVisaCategory = (idx) => {
    axios
      .post(
        url + "pricecompair/updatepricecompair",
        {
          id: idx,
          provided_name: state.provided_name,
          price: state.price,
          visa_id: state.visa_id,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Updated Sucessfully", {
            position: "top-center",
          });
          window.scrollTo({ top: 500, behavior: "smooth" });
        }
        visa_table();
      })
      .catch((error) => {
        //console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      provided_name: "",
      price: "",
      isUpdate: false,
    });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>Price Compare</p>
          </div>
        </div>
        {/* <Divider className={classes.divider} /> */}
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputField
                  type="provided_name"
                  label="Provider Name"
                  fullWidth
                  value={state.provided_name}
                  onChange={(e) => {
                    const input = e.target.value;
                    // Remove non-alphabetic characters
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    // Ensure input does not exceed 50 characters
                    const trimmedInput = validInput.slice(0, 50);
                    setState({
                      ...state,
                      provided_name: trimmedInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Price ($)"
                  fullWidth
                  value={state.price}
                  onChange={(e) => {
                    const input = e.target.value;
                    // Remove non-digit characters
                    const validInput = input.replace(/\D/g, "");
                    // Ensure input does not exceed 15 digits
                    const trimmedInput = validInput.slice(0, 15);
                    setState({
                      ...state,
                      price: trimmedInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={visaList.map((item) => item.visa_name)} // Set options from entry type names
                  value={state.visa_name}
                  label="Visa Names"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryTypeName = v;

                    const selectedEntryType = visaList.find(
                      (item) => item.visa_name === selectedEntryTypeName
                    );

                    setState((prevState) => ({
                      ...prevState,
                      visa_name: v,
                      visa_id: selectedEntryType ? selectedEntryType.id : "",
                    }));
                  }}
                  // Handle onChange event
                />
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.provided_name &&
                        !!state.price &&
                        !!state.visa_id
                      ) {
                        handleUpdateVisaCategory(state.id);
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.provided_name &&
                        !!state.price &&
                        !!state.visa_id
                      ) {
                        handleSaveVisaCategory();
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Providers Name List</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Provider Name", "Price", "Visa", "Action"]}
              rows={[
                ...comparelist
                  .filter(
                    (l) =>
                      l.provided_name
                        .toLowerCase()
                        .includes((state.searchText || "").toLowerCase()) ||
                      l.price
                        .toLowerCase()
                        .includes((state.searchText || "").toLowerCase()) ||
                      l.visa_name
                        .toLowerCase()
                        .includes((state.searchText || "").toLowerCase()) ||
                      (state.searchText || "").toLowerCase() === undefined
                  )
                  .map((vs, idx) => [
                    idx + 1,
                    vs.provided_name,
                    vs.price,
                    visaName,

                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleDelete(vs.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) => {
                          setState({
                            provided_name: vs.provided_name,
                            price: vs.price,
                            isUpdate: true,
                            visa_id: vs.visa_id,
                            visa_name: visaName,
                            id: vs.id,
                          });
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleVisaCategoryDelete}
      />
    </div>
  );
}
