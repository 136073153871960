// ApplicantPDF.js
import React from "react";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import Applicant from "./Applicant";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import CustomButton from "../../components/CustomButton/Index";

const ApplicantPDF = ({ applicant, index, onDownload }) => {
  //console.log(applicant);
  const generatePDF = async () => {
    const doc = <Applicant applicant={{ ...applicant, index }} />;
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Applicant_${index + 1}.pdf`);
    onDownload();
  };

  return (
    <div>
      <PDFViewer width="100%" height="500">
        <Applicant applicant={{ ...applicant, index }} />
      </PDFViewer>
      <CustomButton
        text={"Download Info"}
        variant="contained"
        color="warning"
        onClick={generatePDF}
      />
    </div>
  );
};

export default ApplicantPDF;
