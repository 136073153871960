import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { IoNotificationsSharp } from "react-icons/io5";
import {
  MdOutlineNotificationsActive,
  MdMessage,
  MdPersonAdd,
} from "react-icons/md";
import { IoIosNotificationsOutline } from "react-icons/io";
import url from "../../API";

const NotificationBtn = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([
    { message: "23 people applied recently", icon: <MdPersonAdd /> },
    { message: "10 new messages", icon: <MdMessage /> },
    { message: "5 people has been rejected", icon: <MdPersonAdd /> },
    {
      message: "You have a new offer. Check it out.",
      icon: <MdOutlineNotificationsActive />,
    },
    { message: "200 people got his voucher", icon: <MdMessage /> },
  ]);

  const fetchNotifications = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      } else {
        //console.error("Authorization token not found");
        return;
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${url}notification/getallnotification`,
        requestOptions
      );
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const actualData = await res.json();
      //console.log(actualData);
      setNotifications(actualData.Notification);
    } catch (err) {
      //console.error("Failed to fetch notifications:", err);
    }
  };

  React.useEffect(() => {
    fetchNotifications();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notificationId, redirectUrl) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      } else {
        //console.error("Authorization token not found");
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify({ notification_id: notificationId }),
      };
      const res = await fetch(
        `${url}notification/notificationdetailsbynotificationid`,
        requestOptions
      );
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const actualData = await res.json();
      //console.log(actualData);
      if (actualData.status == 200) {
        window.location.href = redirectUrl;
      }
    } catch (err) {
      //console.error("Failed to mark notification as read:", err);
    }
  };

  return (
    <div>
      <IoIosNotificationsOutline
        size={18}
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            width: "400px",
            marginTop: "30px",
            marginLeft: "30px",
          },
        }}
      >
        <p style={{ fontWeight: "bold", margin: "10px 0" }}>Notifications</p>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#EEEEEE",
            marginBottom: "10px",
          }}
        />
        {notifications?.map((notification, index) => (
          <MenuItem
            key={index}
            style={{
              padding: "10px 20px",
              borderBottom:
                index !== notifications.length - 1
                  ? "1px solid #EEEEEE"
                  : "none",
              backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() =>
              handleNotificationClick(notification.id, notification.url)
            }
          >
            <span style={{ marginRight: "10px", color: "#3f51b5" }}>
              <MdPersonAdd />
            </span>
            {notification.description}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default NotificationBtn;
