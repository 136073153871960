import { Avatar, Divider, Typography, Grid, Button } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import { EditorState, convertFromRaw } from "draft-js";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import DashboardHeader from "../../components/DashHeader/Index";
export default function User_Contact_List() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [searchText, setSearchText] = React.useState("");
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "contactus/getallcontactus",
        requestOptions
      );
      const actualData = await res.json();
      //console.log(actualData);
      setList(actualData.ContactUs);
    } catch (err) {
      //console.log(err);
    }
  };
  React.useEffect(() => {
    table();
  }, []);
  // const handleDeleteCountry = async (idx) => {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }
  //     const data = { id: idx };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(
  //       url +"blog/deleteblog",
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     //console.log(actualData);
  //     // setVisaList(actualData.Country);
  //     if (actualData.status == 200) {
  //       table();
  //     }
  //   } catch (err) {
  //     //console.log(err);
  //   }
  // };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>
              User Contact List
            </p>
          </div>
        </div>

        {/* <Divider className={classes.divider} /> */}
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Name",
              "Email",
              "Phone",
              "Message",
              "Enquiry Type",
            ]}
            rows={[
              ...list
                .sort()
                .filter((l) => {
                  const name = l.name?.toLowerCase() || "";
                  const mail = l.email_id?.toLowerCase() || "";
                  const number = l.phone_number?.toLowerCase() || "";
                  const message = l.message?.toLowerCase() || "";
                  const inquiry_type = l.inquiry_type?.toLowerCase() || "";
                  // // const destination_country =
                  // //   l.destination_country?.toLowerCase() || "";
                  // const purpose = l.purpose?.toLowerCase() || "";
                  const searchLower = searchText.toLowerCase();
                  // const passport_number =
                  //   (l.passport_number ?? "").toString().toLowerCase() || "";
                  return (
                    name.includes(searchLower) ||
                    mail.includes(searchLower) ||
                    message.includes(searchLower) ||
                    number.includes(searchLower) ||
                    inquiry_type.includes(searchLower) ||
                    searchLower === undefined
                  );
                })
                // .filter(
                //   (l) =>
                //     l.full_name
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     String(l.passport_number).includes(searchText) ||
                //     l.occupation
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     l.destination_country
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     l.departure_date.includes(searchText) ||
                //     l.return_date.includes(searchText) ||
                //     l.purpose
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     searchText === undefined
                // )
                .map((vs, idx) => [
                  idx + 1,
                  vs.name,
                  vs.email_id,
                  vs.phone_number,
                  vs.message,
                  vs.inquiry_type,
                ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}
