import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import DashboardHeader from "../../components/DashHeader/Index";
export default function VisaTimePage() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [state, setState] = React.useState({
    visa_time: "",
    searchText: "",
    isUpdate: false,
  });
  const [visaList, setVisaList] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  function table() {
    axios
      .get(url + "visatimecategory/getallvisatimecategory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setVisaList(response.data.Visa_Time_Category);
        //console.log(response.data.Visa_Time_Category);
      })
      .catch((error) => {
        // Handle errors
        //console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table();
  }, []);
  const handleOpenDialog = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setDeleteId(null);
  };

  const handleSaveVisaCategory = () => {
    if (state.visa_time == "") {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      axios
        .post(
          url + "visatimecategory/createvisatimecategory",
          {
            visa_time: state.visa_time,
          },
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            toast.success("Created Sucessfully", {
              position: "top-center",
            });
            // Assuming `table()` refreshes or updates the UI
            window.scrollTo({ top: 300, behavior: "smooth" });
            table();
            setState({
              visa_time: "",
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
        })
        .catch((error) => {
          //console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };
  const handleVisaCategoryDelete = (idx) => {
    axios
      .post(
        url + "visatimecategory/deletevisatimecategory",
        {
          id: deleteId,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        toast.success("Deleted Sucessfully", {
          position: "top-center",
        });
        table();
        setOpen(false);
      })
      .catch((error) => {
        //console.error("Error fetching data:", error);
        // Handle error here
      });
  };
  const handleUpdateVisaCategory = (idx) => {
    if (state.visa_time == "") {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      axios
        .post(
          url + "visatimecategory/updatevisatimecategory",
          {
            id: idx,
            visa_time: state.visa_time,
          },
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            // Assuming `table()` refreshes or updates the UI
            toast.success("Updated Sucessfully", {
              position: "top-center",
            });
            window.scrollTo({ top: 300, behavior: "smooth" });
            table();
            setState({
              visa_time: "",
              isUpdate: false,
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
        })
        .catch((error) => {
          //console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginBlock: "6px", fontSize: "22px" }}>
              Visa Category
            </p>
          </div>
        </div>
        {/* <Divider className={classes.divider} /> */}
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputField
                  label="Visa Category"
                  fullWidth
                  value={state.visa_time}
                  onChange={(e) => {
                    const input = e.target.value;
                    // Remove non-alphabetic characters
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    // Ensure input does not exceed 50 characters
                    const trimmedInput = validInput.slice(0, 50);
                    setState({
                      ...state,
                      visa_time: trimmedInput,
                    });
                  }}
                />
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateVisaCategory(state.visaId);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveVisaCategory();
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Visa Category List</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Visa Category", "Action"]}
              rows={[
                ...visaList
                  .filter((l) => {
                    const visa_time = l.visa_time?.toLowerCase() || "";

                    const searchLower = (state.searchText || "").toLowerCase();
                    return (
                      visa_time.includes(searchLower) ||
                      searchLower === undefined
                    );
                  })
                  .map((vs, idx) => [
                    idx + 1,
                    vs.visa_time,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleOpenDialog(vs.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) => {
                          setState({
                            visa_time: vs.visa_time,
                            isUpdate: true,
                            visaId: vs.id,
                          });
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        onConfirm={handleVisaCategoryDelete}
      />
    </div>
  );
}
