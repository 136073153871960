import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaEdit, FaEye, FaTag, FaTimes, FaUserFriends } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import axios from "axios";
import endPoints from "../../config/endPoint";
import { Editor } from "react-draft-wysiwyg";
import CustomDatePicker from "../../components/CustomDateField/Index";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CountryTable from "../../components/CountryTable/Index";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
export default function Immigration() {
  const navigate = useNavigate();
  const classes = offersStyles();
  const location = useLocation();
  const { visaId } = location.state || {};
  const token = localStorage.getItem("tkn");
  const [list, setList] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deletepriceId, setDeletepriceId] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [state, setState] = React.useState({
    position: "",
    image: "",
    name: "",
    type: "",
    visa_name: "",
    visa_id: "",
  });
  function table() {
    axios
      .post(
        url + "visa/immigrationofficerdetailsbyvisaid",
        {
          visa_id: visaId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the response
        setList(response.data.Officer_details);
        setState((prevState) => ({
          ...prevState,
          visa_id: visaId,
          visa_name: response.data.Visa_name,
        }));
        // //console.log(response.data.Visa_Information);
      })
      .catch((error) => {
        // Handle errors
        //console.error("Error fetching data:", error);
      });
  }
  const handleVisanameChange = (v) => {
    const selectedEntryTypeName = v;

    const selectedEntryType = list.find(
      (item) => item.visa_name === selectedEntryTypeName
    );

    setState((prevState) => ({
      ...prevState,
      visa_name: v,
      visa_id: selectedEntryType ? selectedEntryType.id : "",
    }));
    //console.log(state);
  };
  React.useEffect(() => {
    table();

    // if (localStorage.getItem("updateOffice")) {
    //   const visaid = localStorage.getItem("updateVisaId");
    //   const visaname = localStorage.getItem("updateVisaName");
    //   const updateobj = JSON.parse(localStorage.getItem("updateOffice"));
    //   // //console.log(updateobj.id);
    //   setState({
    //     visa_id: visaid,
    //     visa_name: visaname,
    //     position: updateobj.officer_position,
    //     image: updateobj.officer_photo,
    //     name: updateobj.officer_name,
    //     type: updateobj.officer_type,
    //     isUpdate: true,
    //     officerid: updateobj.id,
    //   });
    // localStorage.removeItem("updateId");
    // handleUpdateVisaCategory(updateobj.id);
    // }
  }, []);

  const handleDeleteOfficer = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: deletepriceId };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "immigrationofficerlist/deleteimmigrationofficerlist",
        requestOptions
      );
      const actualData = await res.json();
      //console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table();
        toast.success("Deleted Sucessfully", {
          position: "top-center",
        });
        // axios
        //   .post(
        //     url +"visaofficermap/deletevisaofficermap",
        //     {
        //       id: office_map,
        //     },
        //     {
        //       headers: {
        //         /* Your headers here */
        //         "Content-Type": "application/json", // Example header
        //         Authorization: `Bearer ${token}`, // Example authorization header
        //       },
        //     }
        //   )
        //   .then((r) => {
        //     table();
        //   })
        //   .catch((error) => {
        //     //console.error("Error fetching data:", error);
        //     // Handle error her
        //   });
        setConfirmDialogOpen(false);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const handleSaveOfficer = async () => {
    if (!!state.image && !!state.name && !!state.position && !!state.type) {
      try {
        const loginHeaders = new Headers();
        loginHeaders.append("Content-Type", "application/json");

        // Assuming you have an authorization token stored in localStorage
        const authToken = localStorage.getItem("tkn");
        if (authToken) {
          loginHeaders.append("Authorization", `Bearer ${authToken}`);
        }
        const data = {
          officer_name: state.name,
          officer_photo: state.image,
          officer_position: state.position,
          officer_type: state.type,
        };
        const requestOptions = {
          method: "POST",
          headers: loginHeaders,
          body: JSON.stringify(data),
        };
        const res = await fetch(
          url + "immigrationofficerlist/createimmigrationofficerlist",
          requestOptions
        );
        const actualData = await res.json();
        //console.log(actualData);
        // setVisaList(actualData.Country);
        if (actualData.status == 200) {
          // table();
          axios
            .post(
              url + "visaofficermap/createvisaofficermap",
              {
                visa_id: visaId,
                officer_id: actualData.new_immigration_officer_id,
              },
              {
                headers: {
                  /* Your headers here */
                  "Content-Type": "application/json", // Example header
                  Authorization: `Bearer ${token}`, // Example authorization header
                },
              }
            )
            .then((r) => {
              if (r.data.status == 200) {
                table();
                setState((prevState) => ({
                  ...prevState,
                  position: "",
                  image: "",
                  name: "",
                  type: "",
                  isUpdate: false,
                }));
                toast.success("Created Sucessfully", {
                  position: "top-center",
                });
              }
            })
            .catch((error) => {
              //console.error("Error fetching data:", error);
              // Handle error here
            });
        }
      } catch (err) {
        //console.log(err);
      }
    } else {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    }
  };
  const handleUpdateOffice = async (id) => {
    if (!!state.image && !!state.name && !!state.position && !!state.type) {
      try {
        const loginHeaders = new Headers();
        loginHeaders.append("Content-Type", "application/json");

        // Assuming you have an authorization token stored in localStorage
        const authToken = localStorage.getItem("tkn");
        if (authToken) {
          loginHeaders.append("Authorization", `Bearer ${authToken}`);
        }
        const data = {
          officer_name: state.name,
          officer_photo: state.image,
          officer_position: state.position,
          officer_type: state.type,
          id: id,
        };
        const requestOptions = {
          method: "POST",
          headers: loginHeaders,
          body: JSON.stringify(data),
        };
        const res = await fetch(
          url + "immigrationofficerlist/updateimmigrationofficerlist",
          requestOptions
        );
        const actualData = await res.json();
        //console.log(actualData);
        // setVisaList(actualData.Country);
        if (actualData.status == 200) {
          table();
          setState((prevState) => ({
            ...prevState,
            position: "",
            image: "",
            name: "",
            type: "",
            isUpdate: false,
          }));
          toast.success("Updated Sucessfully", {
            position: "top-center",
          });
          // axios
          //   .post(
          //     url +"visaofficermap/updatevisaofficermap",
          //     {
          //       visa_id: state.visa_id,
          //       id: localStorage.getItem("officerMapId"),
          //       officer_id: id,
          //       //description pass the text only
          //       //remove the thumbnail image
          //       //json pasrse string to send description
          //       // visa_time: state.visa_time,
          //     },
          //     {
          //       headers: {
          //         /* Your headers here */
          //         "Content-Type": "application/json", // Example header
          //         Authorization: `Bearer ${token}`, // Example authorization header
          //       },
          //     }
          //   )
          //   .then((r) => {
          //     localStorage.removeItem("officer");
          //     localStorage.removeItem("updateVisaId");
          //     localStorage.removeItem("officerMapId");
          //     localStorage.removeItem("updateVisaName");
          //     localStorage.removeItem("updateOffice");
          //     setState({
          //       position: "",
          //       image: "",
          //       name: "",
          //       type: "",
          //       visa_name: "",
          //       visa_id: "",
          //       isUpdate: false,
          //     });
          //     navigate("/immigration_list");
          //   })
          //   .catch((error) => {
          //     //console.error("Error fetching data:", error);
          //     // Handle error here
          //   });
        }
      } catch (err) {
        //console.log(err);
      }
    } else {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    }
  };
  const handleDelete = (idx) => {
    setDeletepriceId(idx);
    setConfirmDialogOpen(true);
  };
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              Immigration Officer Form
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={4}>
              <CustomInputAndSelectField
                options={["Immigration", "VisaRoot officer"]}
                value={
                  state.type == 1
                    ? "Immigration"
                    : state.type == 2
                    ? "VisaRoot officer"
                    : ""
                }
                changeCallBack={(e, v) => {
                  if (v == "Immigration") {
                    setState({
                      ...state,
                      type: 1,
                    });
                  }
                  if (v == "VisaRoot officer") {
                    setState({
                      ...state,
                      type: 2,
                    });
                  }
                }}
                label="Officer Type"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInputField
                fullWidth
                label="Officer Position"
                value={state.position}
                onChange={(e) => {
                  const input = e.target.value;
                  // Remove non-alphabetic characters
                  const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                  // Ensure input does not exceed 50 characters
                  const trimmedInput = validInput.slice(0, 50);
                  setState({
                    ...state,
                    position: trimmedInput,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInputField
                fullWidth
                label="Officer Name"
                value={state.name}
                onChange={(e) => {
                  const input = e.target.value;
                  // Remove non-alphabetic characters
                  const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                  // Ensure input does not exceed 50 characters
                  const trimmedInput = validInput.slice(0, 50);
                  setState({
                    ...state,
                    name: trimmedInput,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInputAndSelectField
                options={list.map((item) => item.visa_name)} // Set options from entry type names
                value={state.visa_name}
                label="Visa Name"
                fullWidth
                changeCallBack={(e, v) => {
                  //console.log(v);
                  handleVisanameChange(v);
                }}
                // Handle onChange event
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInputField
                fullWidth
                label="Officer Photo"
                type="file"
                onChange={(e) => {
                  const imageRef = ref(storage, `/officers+${Math.random()}`);
                  uploadBytes(imageRef, e.target.files[0]).then(() => {
                    getDownloadURL(imageRef)
                      .then((url) => {
                        setState({
                          ...state,
                          image: url,
                        });
                      })
                      .catch((error) => {
                        //console.log(error);
                      });
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              {state.image && (
                <>
                  <p> image</p>
                  <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={state.image}
                    style={{
                      margin: "auto",
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={12}>
              {state.isUpdate ? (
                <Button
                  variant="contained"
                  fullWidth
                  color="warning"
                  onClick={(e) => {
                    if (!!state.name) {
                      handleUpdateOffice(state.id);
                    }
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={(e) => {
                    handleSaveOfficer();
                  }}
                >
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
        </div>

        <Paper className={classes.paper}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Officer Name",
              "Officer Photo",
              "Officer Position",
              "Officer Type",
              "Visa Name",
              "Action",
            ]}
            rows={[
              ...list
                // .filter(
                //   (l) =>
                //     l.officer_name
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     l.officer_position
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     l.visa_name
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     searchText.toLowerCase() === undefined
                // )
                .map((vs, idx) => [
                  idx + 1,
                  vs.officer_name,
                  <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={vs.officer_photo}
                    style={{
                      margin: "auto",
                    }}
                  />,

                  vs.officer_position,
                  vs.officer_type == 1
                    ? "Immigration"
                    : vs.officer_type == 2
                    ? "VisaRoot officer"
                    : "",
                  state.visa_name,
                  <span className={classes.actionBtn}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      onClick={(e) => handleDelete(vs.id)}
                      icon={<FaTimes />}
                    />
                    <CustomButton
                      variant="contained"
                      color="warning"
                      onClick={(e) => {
                        setState((prevState) => ({
                          ...prevState,
                          position: vs.officer_position,
                          image: vs.officer_photo,
                          name: vs.officer_name,
                          type: vs.officer_type,
                          id: vs.id,
                          isUpdate: true,
                        }));
                      }}
                      icon={<FaEdit />}
                    />
                  </span>,
                ]),
            ]}
          />
        </Paper>
        <ToastContainer />
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleDeleteOfficer}
      />
    </div>
  );
}
